import {
  IntegrationAccounts,
  Account,
  LawPayAccounts,
} from '@/types/integrations.type';
import gql from 'graphql-tag';
import http from './http.service';

export default class {
  public static async getDualAccounts(): Promise<IntegrationAccounts> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query {
            accounts {
              _id
              userId
              type
              accountName
              lawPayAccount {
                _id
                type
                lawPayAccount
              }
            }

            lawPayAccounts {
              id
              name
              trustAccount
              account
            }
          }
        `,
      });

      return Promise.resolve({
        lawPayAccounts: data.data.lawPayAccounts,
        accounts: data.data.accounts,
      } as IntegrationAccounts);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static async getLawPayAccounts(): Promise<LawPayAccounts> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query {
            lawPayAccounts {
              credential
            }
          }
        `,
      });

      return Promise.resolve(data.data.lawPayAccounts as LawPayAccounts);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static async getAccounts(): Promise<Account[]> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query {
            accounts {
              _id
              userId
              type
              accountName
              lawPayAccount {
                _id
                type
                lawPayAccount
              }
            }
          }
        `,
      });
      return Promise.resolve(data.data.accounts);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static async syncAccount(syncMatchAccount: any): Promise<Account> {
    try {
      const { data } = await http.graphql<any>({
        mutation: gql`
          mutation matchAccount($syncMatchAccount: syncMatchAccount!) {
            matchAccount(syncMatchAccount: $syncMatchAccount) {
              _id
              userId
              type
              accountName
              lawPayAccount {
                _id
                type
                lawPayAccount
              }
            }
          }
        `,
        variables: { syncMatchAccount },
      });
      return Promise.resolve(data.data.matchAccount);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static async addPaymentMethod(
    newPaymentMethodContact: any,
  ): Promise<object> {
    try {
      const { data } = await http.graphql<any>({
        mutation: gql`
          mutation paymentMethodAdd(
            $newPaymentMethodContact: newPaymentMethodContact!
          ) {
            paymentMethodContactAdd(
              newPaymentMethodContact: $newPaymentMethodContact
            ) {
              _id
              contactId
              lawPayPaymentMethod
              paymentMethods
            }
          }
        `,
        variables: { newPaymentMethodContact },
      });
      return Promise.resolve(data.data.paymentMethodContactAdd);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static async getPaymentMethods(contactId: number): Promise<object> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query paymentMethodContact($contactId: Int) {
            paymentMethodContact(contactId: $contactId) {
              _id
              contactId
              lawPayPaymentMethod
              paymentMethods
            }
          }
        `,
        variables: { contactId },
      });
      return Promise.resolve(data.data.paymentMethodContact);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static async deletePaymentMethod(
    contactId: number,
    paymentMethodId: string,
  ): Promise<object> {
    try {
      const { data } = await http.graphql<any>({
        mutation: gql`
          mutation paymentMethodContactDelete(
            $contactId: Int!
            $paymentMethodId: String!
          ) {
            paymentMethodContactDelete(
              contactId: $contactId
              paymentMethodId: $paymentMethodId
            ) {
              _id
              contactId
              lawPayPaymentMethod
              paymentMethods
            }
          }
        `,
        variables: { contactId, paymentMethodId },
      });
      return Promise.resolve(data.data.paymentMethodContactDelete);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
