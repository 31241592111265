import { App } from 'vue';

// Ant design components
import {
  Steps,
  Button,
  Select,
  Form,
  InputNumber,
  Input,
  Table,
  Radio,
  Col,
  Row,
  Checkbox,
  DatePicker,
  Switch,
  Result,
  Spin,
  Card,
  Tabs,
  Tooltip,
  Modal,
  List,
  Tag,
} from 'ant-design-vue';

const components = [
  Steps,
  Button,
  Select,
  Form,
  InputNumber,
  Input,
  Table,
  Radio,
  Col,
  Row,
  Checkbox,
  DatePicker,
  Switch,
  Result,
  Spin,
  Card,
  Tabs,
  Tooltip,
  Modal,
  List,
  Tag,
];
export default (app: App) => {
  components.forEach(component => app.use(component));
};
