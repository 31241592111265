import { Integration, Account } from '@/types/integrations.type';
import { computed, reactive } from 'vue';

interface State {
  integrations: Integration[];
  accounts: Account[];
}

const state = reactive<State>({
  integrations: [],
  accounts: [],
});

export default () => {
  const integrations = computed(() => state.integrations);
  const accounts = computed(() => state.accounts);

  const setIntegrations = (payload: Integration[]) => {
    state.integrations = payload;
  };

  const setAccounts = (payload: Account[]) => {
    state.accounts = payload;
  };

  const hasLawpayIntegration = computed(() => {
    if (!state.integrations || !state.integrations.length) {
      return false;
    }

    return state.integrations.some(integration => {
      return (
        integration.prefix === 'lawpay' &&
        integration.connected &&
        state.accounts.some(
          account =>
            account.lawPayAccount.length &&
            account.lawPayAccount.some(
              lawPayAccount => lawPayAccount.type === 'card',
            ),
        )
      );
    });
  });

  return {
    integrations,
    hasLawpayIntegration,
    accounts,
    setIntegrations,
    setAccounts,
  };
};
