import {
  LawPayMethods,
  LawPayState,
  LawPayTarget,
  LawPayType,
} from '@/types/payment-form.type';
import { onMounted, Ref, ref } from 'vue';
import { IntegrationsService } from '@/services';
import CryptoJS from 'crypto-js';

export default function(
  type: LawPayType,
  callback?: (state: LawPayState) => void,
) {
  const lawPayHostedField = ref<LawPayMethods>() as Ref<LawPayMethods>;

  /** CSS for LawPay fields */
  const lawPayFieldCSS = {
    'box-sizing': 'border-box',
    margin: 0,
    'font-variant': 'tabular-nums',
    'list-style': 'none',
    'font-feature-settings': 'tnum',
    position: 'relative',
    display: 'inline-block',
    width: '99%',
    padding: '4px 11px',
    color: 'rgba(0, 0, 0, 0.65)',
    'font-size': '14px',
    'line-height': '1.5715',
    'background-color': '#fff',
    'background-image': 'none',
    border: '1px solid #d9d9d9',
    'border-radius': '2px',
    transition: 'all 0.3s',
    '::placeholder': {
      color: 'rgba(0,0,0,0.3)',
    },
    ':hover': {
      'border-color': '#40a9ff',
      'border-right-width': '1px !important',
    },
    ':focus': {
      'border-color': '#40a9ff',
      'border-right-width': '1px !important',
      outline: 0,
    },
  };

  /** LawPay fields ids */
  const lawPayFieldsConfig = {
    card: [
      {
        id: 'credit_card_field',
        type: 'credit_card_number',
        placeholder: 'Card Number',
      },
      {
        id: 'cvv_field',
        type: 'cvv',
        placeholder: 'CVV',
      },
    ],
    e_check: [
      {
        id: 'bank_account_number',
        type: 'bank_account_number',
        placeholder: 'Bank Account Number',
      },
      {
        id: 'routing_number',
        type: 'routing_number',
        placeholder: 'Routing Number',
      },
    ],
  };

  /** Display errors in LawPay fields */
  const lawPayDisplayErrors = (target: LawPayTarget) => {
    const input = document.querySelector(target.selector) as HTMLDivElement;

    const span = input.querySelector('small');
    if (!span) return;

    if (target.error) {
      span.textContent = target.error;
      span.style.display = 'block';

      input.classList.add('has-error');
    } else {
      input.classList.remove('has-error');
      span.textContent = '';
      span.style.display = 'none';
    }
  };

  /** Create custom error labels in LawPay fields */
  const createErrorLabelFields = () => {
    lawPayFieldsConfig[type].map(({ id }) => {
      const field = document.getElementById(id);

      // Create small element and insert in LawPay field
      const smallField = document.createElement('small');
      smallField.classList.add('law-pay-error');

      field?.appendChild(smallField);
    });
  };

  /** LawPay fields callback */
  const hostedFieldsCallback = (state: LawPayState) => {
    lawPayDisplayErrors(state.target);
    !!callback && callback(state);
  };

  /** Generate LawPay fields IDs */
  const generateFieldIDs = () => {
    return lawPayFieldsConfig[type].reduce(
      (obj, field) => {
        return { ...obj, [field.type]: field.id };
      },
      {
        credit_card_number: '',
        cvv: '',
        bank_account_number: '',
        routing_number: '',
      },
    );
  };

  /** Validate form */
  const validateForm = async (
    validateAntFields: (fields?: string | string[]) => Promise<any>,
    fieldsToValidate?: string | string[],
  ) => {
    let done = true;

    // Validate LawPay fields
    const { isReady, fields } = lawPayHostedField.value.getState();

    if (!isReady) {
      done = false;
      fields.forEach(target => lawPayDisplayErrors(target));
    }

    // Validate other fields
    try {
      await validateAntFields(fieldsToValidate);
    } catch (error) {
      done = false;
    }

    return Promise.resolve(done);
  };

  /** Lifecycle hooks */
  onMounted(async () => {
    // Get lawpay accounts
    const lawPayAccounts: any = await IntegrationsService.getLawPayAccounts();
    const bytes = CryptoJS.AES.decrypt(
      lawPayAccounts[0].credential,
      process.env.VUE_APP_SECRET_PHRASE,
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const lawPayConfig = lawPayFieldsConfig[type];

    const hostedFieldsConfig = {
      publicKey: decryptedData.publicKey,
      input: {
        css: {
          ':invalid': { 'border-color': 'red' },
          ':valid': { 'border-color': '#d9d9d9' },
        },
      },
      fields: lawPayConfig.map(field => ({
        selector: `#${field.id}`,
        input: {
          type: field.type,
          placeholder: field.placeholder,
          css: lawPayFieldCSS,
        },
      })),
    };

    lawPayHostedField.value = (window as any).AffiniPay.HostedFields.initializeFields(
      hostedFieldsConfig,
      hostedFieldsCallback,
    );

    createErrorLabelFields();
  });

  return {
    lawPayHostedField,
    lawPayFieldID: generateFieldIDs(),
    validateForm,
  };
}
