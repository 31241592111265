import { createApp } from 'vue';
import router from './router';
import App from './App.vue';

import registerAntComponents from '@/plugins/ant-design';

const app = createApp(App);

/** Import globally ant design components */
registerAntComponents(app);

import '@/assets/scss/main.scss';

app.use(router).mount('#app');
